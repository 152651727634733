<template>
  <router-link :to="link?.link" v-if="link?.routerLink" class="button-link">
    {{ link.text }}
  </router-link>
  <a :href="link?.link" class="button-link" v-else>{{ link.text }}</a>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Link } from "@/interfaces/components/links/RouterLinkPropTypes";

export default defineComponent({
  name: "ButtonLink",
  props: {
    link: Object as PropType<Link>,
  },
});
</script>

<style lang="sass">
@import "@/assets/sass/base"

.button-link
  padding: 15px 20px
  border-radius: 50px
  color: $button-link-color
  border: 1px solid $button-link-border-color
  transition: color, border .5s
  font-weight: 600
  font-size: 12px

  &:hover
    color: $button-link-hover-color
</style>
