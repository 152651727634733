<template>
  <HeaderVue class="mb-40"></HeaderVue>
  <div class="messages" v-if="$store.state.messages.length">
    <VueMessages
      :message="msg"
      v-for="(msg, idx) in $store.state.messages"
      :key="idx"
    ></VueMessages>
  </div>
  <router-view />
  <FooterVue></FooterVue>
</template>

<script lang="ts">
import HeaderVue from "@/layouts/HeaderVue.vue";
import { defineComponent } from "vue";
import FooterVue from "@/layouts/FooterVue.vue";
import VueMessages from "@/components/UI/VueMessages.vue";

export default defineComponent({
  name: "App",
  components: { VueMessages, FooterVue, HeaderVue },
});
</script>

<style lang="sass">
.messages .flash-message
  margin-bottom: 20px
</style>
