import { AUCTION_API_BASE_URL } from "@/constants";
import AuctionCarsResponse, {
  AuctionCar,
  AuctionCarsQueryParams,
  AuctionCarsRetrieveResponse,
} from "@/interfaces/components/cars/AuctionCarTypes";

export async function getAuctionCars(
  params: AuctionCarsQueryParams
): Promise<AuctionCarsResponse> {
  return await fetch(
    `${AUCTION_API_BASE_URL}/cars?` + new URLSearchParams(params).toString(),
    {
      method: "GET",
    }
  ).then((response: Response) => {
    return response.json();
  });
}

export async function searchCarByLotNumber(
  lot: string,
  domain: "copart_com" | "iaai_com" = "copart_com"
): Promise<AuctionCarsRetrieveResponse> {
  return await fetch(
    `${AUCTION_API_BASE_URL}/cars/search-lot/${lot}/${domain}`,
    {
      method: "GET",
    }
  ).then((response: Response) => {
    return response.json();
  });
}

export async function searchCarByVinNumber(
  vin: string
): Promise<AuctionCarsRetrieveResponse> {
  return await fetch(`${AUCTION_API_BASE_URL}/cars/search-vin/${vin}`, {
    method: "GET",
  }).then((response: Response) => {
    return response.json();
  });
}

export function resolveAuctionCarData(obj: AuctionCar) {
  if (obj.lots.length) {
    if (obj.lots[0].sale_date) {
      obj.lots[0].sale_date = new Date(obj.lots[0].sale_date);
    }
    if (obj.lots[0].created_at) {
      obj.lots[0].created_at = new Date(obj.lots[0].created_at);
    }
    if (obj.lots[0].updated_at) {
      obj.lots[0].updated_at = new Date(obj.lots[0].updated_at);
    }
  }
  if (obj.fuel) {
    if (obj.fuel.id === 1) {
      obj.fuel.name = "Дизель";
    } else if (obj.fuel.id === 2) {
      obj.fuel.name = "Электро";
    } else if (obj.fuel.id === 3) {
      obj.fuel.name = "Гибрид";
    } else if (obj.fuel.id === 4) {
      obj.fuel.name = "Бензин";
    } else if (obj.fuel.id === 5) {
      obj.fuel.name = "Бензин";
    } else if (obj.fuel.id === 6) {
      obj.fuel.name = "Гибкое";
    } else {
      obj.fuel.name = "Водород";
    }
  }
  if (obj.drive_wheel) {
    if (obj.drive_wheel.name === "rear") {
      obj.drive_wheel.name = "Задний";
    } else if (obj.drive_wheel.name === "front") {
      obj.drive_wheel.name = "Передний";
    } else {
      obj.drive_wheel.name = "Полный";
    }
  }
  if (obj.transmission) {
    if (obj.transmission.name === "automatic") {
      obj.transmission.name = "Автомат";
    } else {
      obj.transmission.name = "Ручной";
    }
  }
  if (obj.lots[0].condition) {
    if (obj.lots[0].condition?.name === "run_and_drives") {
      obj.lots[0].condition.name = "Заводится и едет";
    } else if (obj.lots[0].condition?.name === "for_repair") {
      obj.lots[0].condition.name = "Машина для ремонта";
    } else if (obj.lots[0].condition?.name === "to_be_dismantled") {
      obj.lots[0].condition.name = "Машина на запчасти";
    } else if (obj.lots[0].condition?.name === "not_run") {
      obj.lots[0].condition.name = "Не едет";
    } else if (obj.lots[0].condition?.name === "used") {
      obj.lots[0].condition.name = "Подержанная";
    } else if (obj.lots[0].condition?.name === "unconfirmed") {
      obj.lots[0].condition.name = "Статус не подтвержден";
    } else if (obj.lots[0].condition?.name === "engine_starts") {
      obj.lots[0].condition.name = "Мотор заводится";
    } else {
      obj.lots[0].condition.name = "Не заводится";
    }
  }
  if (obj.lots[0].damage) {
    if (obj.lots[0].damage?.main.name === "Front End") {
      obj.lots[0].damage.main.name = "Повреждение передней части";
    } else if (obj.lots[0].damage?.main.name === "Frame Damage") {
      obj.lots[0].damage.main.name = "Повреждение рамы";
    } else if (obj.lots[0].damage?.main.name === "Burn") {
      obj.lots[0].damage.main.name = "Ожоговый ущерб";
    } else if (obj.lots[0].damage?.main.name === "Burn - Engine") {
      obj.lots[0].damage.main.name = "Сгоревший двигатель";
    } else if (obj.lots[0].damage?.main.name === "Burn - Interior") {
      obj.lots[0].damage.main.name = "Сгоревший салон";
    } else {
      obj.lots[0].damage.main.name = "Другое";
    }
  }
  if (obj.lots[0].keys_available) {
    obj.lots[0].keys_available = "Да";
  } else {
    obj.lots[0].keys_available = "Нет";
  }
  if (obj.lots[0].airbags) {
    obj.lots[0].airbags.name = "Нетронутые";
  }
}

export function resolveAuctionCarsData(auctionCars: AuctionCar[]) {
  auctionCars.forEach((obj) => {
    resolveAuctionCarData(obj);
  });
}
