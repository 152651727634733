import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f01c86b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkTime = _resolveComponent("WorkTime")!
  const _component_ContactPhone = _resolveComponent("ContactPhone")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WorkTime, { text: "Пн-Пт с 10:00 - 19:00" }),
    _createVNode(_component_ContactPhone, { number: "+7 (495) 106-20-20" }),
    _createVNode(_component_ContactPhone, { number: "+7 (495) 106-20-20" })
  ]))
}