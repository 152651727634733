import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "messages"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderVue = _resolveComponent("HeaderVue")!
  const _component_VueMessages = _resolveComponent("VueMessages")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterVue = _resolveComponent("FooterVue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderVue, { class: "mb-40" }),
    (_ctx.$store.state.messages.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.messages, (msg, idx) => {
            return (_openBlock(), _createBlock(_component_VueMessages, {
              message: msg,
              key: idx
            }, null, 8, ["message"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_component_FooterVue)
  ], 64))
}