<template>
  <div class="contact-data">
    <WorkTime text="Пн-Пт с 10:00 - 19:00"></WorkTime>
    <ContactPhone number="+7 (495) 106-20-20"></ContactPhone>
    <ContactPhone number="+7 (495) 106-20-20"></ContactPhone>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WorkTime from "@/components/UI/WorkTime.vue";
import ContactPhone from "@/components/UI/ContactPhone.vue";

export default defineComponent({
  name: "HeaderContacts",
  components: { ContactPhone, WorkTime },
});
</script>

<style scoped lang="sass">
.work-time
  margin-left: 10px
</style>
