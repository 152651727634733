import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-466160ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopFooter = _resolveComponent("TopFooter")!
  const _component_MidFooter = _resolveComponent("MidFooter")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_TopFooter),
    _createVNode(_component_MidFooter)
  ]))
}