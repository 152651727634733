<template>
  <header class="header container">
    <SiteLogo
      @click="$router.push({ name: 'home' })"
      class="header-logo"
    ></SiteLogo>
    <HeaderLinks></HeaderLinks>
    <HeaderContacts></HeaderContacts>
    <BurgerMenu class="header-burger-menu"></BurgerMenu>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SiteLogo from "@/components/UI/SiteLogo.vue";
import HeaderLinks from "@/blocks/HeaderLinks.vue";
import HeaderContacts from "@/blocks/HeaderContacts.vue";
import BurgerMenu from "@/blocks/BurgerMenu.vue";

export default defineComponent({
  name: "HeaderVue",
  components: { BurgerMenu, HeaderContacts, HeaderLinks, SiteLogo },
});
</script>

<style lang="sass" scoped>
@import "@/assets/sass/base"

header
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 60px
  color: $main-text-color

  & .header-logo
    width: 18%

  & .header-links
    width: 60%

  & .header-contacts
    width: 15%

  & .header-burger-menu
    width: 10%
    display: none
</style>
