import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/catalog",
    name: "catalogPage",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/catalog/own-cars",
    name: "ownCarsPage",
    component: () => import("@/views/OwnCatalogView.vue"),
  },
  {
    path: "/catalog/own-cars/:id(\\d+)",
    name: "ownCarDetail",
    component: () => import("@/views/OwnCarDetail.vue"),
  },
  {
    path: "/catalog/auction-cars",
    name: "auctionCars",
    component: () => import("@/views/AuctionCarsCatalog.vue"),
  },
  {
    path: "/catalog/auction-cars/:lotNumber",
    name: "auctionCarDetail",
    component: () => import("@/views/AuctionCarsDetail.vue"),
  },
  {
    path: "/:notFound(.*)*",
    name: "notFound",
    component: () => import("@/views/errors/VueNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
