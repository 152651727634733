import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-aa818072"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.link?.routerLink)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.link.link,
        class: "default-link"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.link.text), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: _ctx.link?.link,
        class: "default-link"
      }, _toDisplayString(_ctx.link.text), 9, _hoisted_1))
}