<template>
  <div class="logo">
    <img src="@/assets/img/svg/logo.svg" alt="logo" />
    <small>Прозрачный импорт авто из-за границы в Россию</small>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SiteLogo",
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/base"

.logo
  display: flex
  align-items: center
  font-size: 12px
  font-weight: 400
  justify-content: space-between
  line-height: 12.5px
  color: $main-text-color

  & img
    width: 100%
    margin-right: 10px
</style>
