import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-30538e78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-link" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  class: "link-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.link?.routerLink)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.link.link
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.link.iconPath,
              alt: "icon"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.link?.link
        }, [
          _createElementVNode("img", {
            src: _ctx.link?.iconPath,
            alt: "icon"
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_3)),
    (_ctx.link?.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.link.text), 1))
      : _createCommentVNode("", true)
  ]))
}