<template>
  <div class="mid-footer container">
    <div class="first-section">
      <p class="organization-name">{{ organizationName }}</p>
      <p class="organization-inn">{{ organizationINN }}</p>
      <p class="organization-id">{{ organizationId }}</p>
      <br />
      <DefaultLink class="org-id" :link="idOrgLink"></DefaultLink>
      <p
        class="address"
        v-for="(address, idx) in organizationAddresses"
        :key="idx"
      >
        {{ address }}
      </p>
    </div>
    <div class="second-section">
      <div class="links">
        <DefaultLink
          v-for="(link, linkIdx) in midFooterLinks"
          :key="linkIdx"
          :link="link"
        ></DefaultLink>
      </div>
      <div class="socials">
        <p>Мы в соц. сетях</p>
        <div class="links">
          <IconLink
            v-for="(icon, iconIdx) in midFooterSocialLinks"
            :key="iconIdx"
            :link="icon"
          ></IconLink>
        </div>
      </div>
    </div>
    <div class="third-section">
      <div class="work-time">
        <WorkTime class="gray" :text="companyWorkTime"></WorkTime>
        <ContactPhone
          v-for="(phone, idx) in contactPhones"
          :key="idx"
          :number="phone"
          class="contact-phone"
        ></ContactPhone>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DefaultLink from "@/components/links/DefaultLink.vue";
import { Link } from "@/interfaces/components/links/RouterLinkPropTypes";
import IconLinkProp from "@/interfaces/components/links/IconLinkPropTypes";
import IconLink from "@/components/links/IconLink.vue";
import WorkTime from "@/components/UI/WorkTime.vue";
import ContactPhone from "@/components/UI/ContactPhone.vue";

export default defineComponent({
  name: "MidFooter",
  components: { ContactPhone, WorkTime, IconLink, DefaultLink },
  data() {
    return {
      organizationName: "ООО АВТОДЖОЙ-МОТОРС",
      organizationINN: "ИНН 7734440640",
      organizationId: "ОГРН: 1217700072259",
      organizationAddresses: [
        "Красногорск, БЦ Красногорск Плаза, Ильинское ш., 1А",
        "г. Москва, ул. Твардовского, 8к4",
      ],
      idOrgLink: {
        text: "Данные о компании в Росеестре",
        link: "#!",
        routerLink: false,
      } as Link,
      midFooterLinks: [
        { text: "Авто из США", link: "#!", routerLink: false },
        { text: "Авто из Китая", link: "#!", routerLink: false },
        { text: "Авто из Кореи", link: "#!", routerLink: false },
        { text: "Авто из Европы", link: "#!", routerLink: false },
        { text: "Процесс покупки  из США", link: "#!", routerLink: false },
        { text: "Процесс покупки  из Китая", link: "#!", routerLink: false },
        { text: "Процесс покупки  из Кореи", link: "#!", routerLink: false },
        { text: "Процесс покупки  из Европы", link: "#!", routerLink: false },
      ] as Array<Link>,
      midFooterSocialLinks: [
        {
          link: "#!",
          routerLink: false,
          iconPath: require("@/assets/img/svg/vk.svg"),
        },
        {
          link: "#!",
          routerLink: false,
          iconPath: require("@/assets/img/svg/telegram.svg"),
        },
        {
          link: "#!",
          routerLink: false,
          iconPath: require("@/assets/img/svg/youtube.svg"),
        },
      ] as Array<IconLinkProp>,
      companyWorkTime: "Пн-Пт с 10:00-19:00",
      contactPhones: ["+7 (495)  106-20-20", "+7 (969)  777-17-90"],
    };
  },
});
</script>

<style scoped lang="sass">
.mid-footer
  padding-top: 35px
  display: flex
  align-items: flex-start
  justify-content: space-between

  & .first-section
    width: 25%

    & p
      font-size: 16px
      font-weight: 400
      line-height: 22px

    & .org-id
      color: #ACA1A1
      display: block
      margin-bottom: 20px

  & .second-section
    width: 42%
    display: flex
    align-items: flex-start
    justify-content: space-between

    & .links
      width: 70%
      display: flex
      flex-wrap: wrap
      flex-direction: column
      height: 150px
      justify-content: space-between

      & a
        margin-bottom: 16px

    & .socials
      width: 25%

      & p
        margin-bottom: 20px

      & .links
        width: 100%
        height: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between

  & .third-section
    width: 25%

    & .work-time .contact-phone
      color: white
      font-weight: 400
      font-size: 40px
</style>
