<template>
  <div class="header-links">
    <HeaderLink
      :link="link"
      v-for="(link, idx) in headerLinks"
      :key="idx"
    ></HeaderLink>
    <ButtonLink
      :link="btnLink"
      v-for="(btnLink, btnIdx) in headerButtonLinks"
      :key="btnIdx"
    ></ButtonLink>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderLink from "@/components/links/HeaderLink.vue";
import ButtonLink from "@/components/links/ButtonLink.vue";
import { Link } from "@/interfaces/components/links/RouterLinkPropTypes";

export default defineComponent({
  name: "HeaderLinks",
  components: { ButtonLink, HeaderLink },
  data() {
    return {
      headerLinks: [
        { text: "УСЛУГИ", link: "#!", routerLink: false },
        { text: "ПРОЦЕСС ПОДБОРА", link: "#!", routerLink: false },
        { text: "О КОМПАНИИ", link: "#!", routerLink: false },
        { text: "КОНТАКТЫ", link: "#!", routerLink: false },
      ] as Array<Link>,
      headerButtonLinks: [
        { text: "ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ", link: "#!", routerLink: false },
      ] as Array<Link>,
    };
  },
});
</script>

<style scoped lang="sass">
.header-links
  display: flex
  align-items: center
  justify-content: space-between
</style>
