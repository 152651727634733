<template>
  <div class="icon-link">
    <router-link :to="link.link" v-if="link?.routerLink">
      <img :src="link.iconPath" alt="icon" />
    </router-link>
    <a :href="link?.link" v-else>
      <img :src="link?.iconPath" alt="icon" />
    </a>
    <span class="link-label" v-if="link?.text">{{ link.text }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue/dist/vue";
import IconLinkProp from "@/interfaces/components/links/IconLinkPropTypes";

export default defineComponent({
  name: "IconLink",
  props: {
    link: Object as PropType<IconLinkProp>,
  },
});
</script>

<style scoped lang="sass">
.icon-link
  display: flex
  flex-direction: column
  align-items: center
</style>
