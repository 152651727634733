<template>
  <router-link :to="link.link" class="default-link" v-if="link?.routerLink">
    {{ link.text }}
  </router-link>
  <a :href="link?.link" class="default-link" v-else>{{ link.text }}</a>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue/dist/vue";
import { Link } from "@/interfaces/components/links/RouterLinkPropTypes";

export default defineComponent({
  name: "DefaultLink",
  props: {
    link: Object as PropType<Link>,
  },
});
</script>

<style scoped lang="sass">
.default-link
  color: white
</style>
