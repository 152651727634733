<template>
  <p class="work-time">{{ text }}</p>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "WorkTime",
  props: {
    text: String,
  },
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/base"

.work-time
  color: $main-text-color
  position: relative

  &.white
    color: white

  &.gray
    color: #A5A5A5

  &::before
    content: ""
    position: absolute
    left: -10px
    width: 5px
    top: 7px
    height: 5px
    border-radius: 50%
    background-color: $work-time-circle-color
</style>
