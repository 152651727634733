<template>
  <router-link
    :to="link?.link"
    v-if="link?.routerLink"
    class="footer-button-link"
  >
    {{ link.text }}
  </router-link>
  <a :href="link?.link" class="footer-button-link" v-else>{{ link.text }}</a>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue/dist/vue";
import { Link } from "@/interfaces/components/links/RouterLinkPropTypes";

export default defineComponent({
  name: "FooterButtonLink",
  props: {
    link: Object as PropType<Link>,
  },
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/base"

.footer-button-link
  padding: 15px 20px
  color: $top-footer-btn-link-border-color
  border-radius: 50px
  border: 1px solid $top-footer-btn-link-border-color
  text-align: center
</style>
