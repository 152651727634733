import { OWN_API_BASE_URL } from "@/constants";
import {
  OwnCar,
  OwnCarsQueryParams,
  OwnCarsResponse,
} from "@/interfaces/components/cars/OwnCarTypes";

export async function getOwnCars(
  params: OwnCarsQueryParams
): Promise<OwnCarsResponse> {
  return await fetch(
    `${OWN_API_BASE_URL}/shop/cars?` + new URLSearchParams(params).toString(),
    {
      method: "GET",
    }
  ).then((response: Response) => {
    return response.json();
  });
}

export async function retrieveOwnCar(carId: number): Promise<OwnCar> {
  return await fetch(`${OWN_API_BASE_URL}/shop/cars/${carId}`, {
    method: "GET",
  }).then((response: Response) => {
    return response.json();
  });
}

export function resolveOwnCarData(obj: OwnCar) {
  if (obj.drive) {
    if (obj.drive === 1) {
      obj.drive = "Задний";
    } else if (obj.drive === 2) {
      obj.drive = "Передний";
    } else {
      obj.drive = "Полный";
    }
  }
  if (obj.box) {
    if (obj.box === 1) {
      obj.box = "Автомат";
    } else {
      obj.box = "Ручной";
    }
  }
  if (obj.carcase) {
    if (obj.carcase === 1) {
      obj.carcase = "Седан";
    } else if (obj.carcase === 2) {
      obj.carcase = "Фургон";
    } else if (obj.carcase === 3) {
      obj.carcase = "Купе";
    } else if (obj.carcase === 4) {
      obj.carcase = "Пикап";
    } else if (obj.carcase === 5) {
      obj.carcase = "Внедорожник";
    } else if (obj.carcase === 6) {
      obj.carcase = "Кабриолет";
    } else if (obj.carcase === 7) {
      obj.carcase = "ВАН";
    } else if (obj.carcase === 8) {
      obj.carcase = "Мото";
    } else if (obj.carcase === 11) {
      obj.carcase = "Хэтчбек";
    } else if (obj.carcase === 12) {
      obj.carcase = "Родстер";
    } else if (obj.carcase === 13) {
      obj.carcase = "Лимузин";
    } else if (obj.carcase === 14) {
      obj.carcase = "Трак";
    } else if (obj.carcase === 15) {
      obj.carcase = "Велосипед";
    } else if (obj.carcase === 18) {
      obj.carcase = "Промышленный";
    } else if (obj.carcase === 19) {
      obj.carcase = "Автобус";
    } else if (obj.carcase === 22) {
      obj.carcase = "Катафалк";
    } else if (obj.carcase === 23) {
      obj.carcase = "Пожарная";
    } else if (obj.carcase === 24) {
      obj.carcase = "Трейлер";
    } else if (obj.carcase === 26) {
      obj.carcase = "Мусорный";
    } else {
      obj.carcase = "Другое";
    }
  }
}

export function resolveOwnCarsData(cars: OwnCar[]) {
  cars.forEach((obj) => {
    resolveOwnCarData(obj);
  });
}
