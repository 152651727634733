<template>
  <div
    class="burger-menu"
    :class="[burgerMenuActive ? 'burger-menu_active' : '']"
  >
    <a href="#!" class="burger-menu_button" @click="toggleMenu">
      <span class="burger-menu_lines"></span>
    </a>
    <nav
      class="burger-menu_nav"
      :style="{ display: burgerMenuActive ? 'flex' : 'none' }"
    >
      <HeaderLink
        :link="link"
        v-for="(link, idx) in headerLinks"
        :key="idx"
        class="burger-menu_link"
        @click="toggleMenu"
      ></HeaderLink>
    </nav>
    <div class="burger-menu_overlay"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderLink from "@/components/links/HeaderLink.vue";
import { Link } from "@/interfaces/components/links/RouterLinkPropTypes";

export default defineComponent({
  name: "BurgerMenu",
  components: { HeaderLink },
  data() {
    return {
      headerLinks: [
        { text: "УСЛУГИ", link: "#!", routerLink: false },
        { text: "ПРОЦЕСС ПОДБОРА", link: "#!", routerLink: false },
        { text: "О КОМПАНИИ", link: "#!", routerLink: false },
        { text: "КОНТАКТЫ", link: "#!", routerLink: false },
      ] as Array<Link>,
      burgerMenuActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.burgerMenuActive = !this.burgerMenuActive;
    },
  },
});
</script>

<style scoped lang="css">
@import "@/assets/sass/_base.sass";

.burger-menu {
  position: relative;
}

.burger-menu_button {
  position: absolute;
  top: -50px;
  right: 10px;
  z-index: 30;
  width: 100%;
  height: 80px;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.burger-menu_lines::before,
.burger-menu_lines::after,
.burger-menu_lines {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #22292d;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.burger-menu_lines {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.burger-menu_lines::before {
  content: "";
  top: -12px;
}

.burger-menu_lines::after {
  content: "";
  top: 12px;
}

.burger-menu_active .burger-menu_lines {
  background-color: transparent;
}

.burger-menu_active .burger-menu_lines::before {
  top: 0;
  transform: rotate(45deg);
  background-color: white;
}

.burger-menu_active .burger-menu_lines::after {
  top: 0;
  transform: rotate(-45deg);
  background-color: white;
}

.burger-menu_nav {
  padding-top: 120px;
  position: fixed;
  top: 0;
  z-index: 20;
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: #d72b36;
  overflow-y: auto;
  right: -100%;
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
}

.burger-menu_active .burger-menu_nav {
  right: 0;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.burger-menu_link {
  padding: 18px 35px;
  font-size: 20px !important;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 400;
  color: white;
  border-bottom: 1px solid #fff;
}

.burger-menu_link:first-child {
  border-top: 1px solid #fff;
}

.burger-menu_link:hover {
  filter: brightness(0.9);
}

.burger-menu_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.burger-menu_active .burger-menu_overlay {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
