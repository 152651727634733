import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-85fff9ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteLogo = _resolveComponent("SiteLogo")!
  const _component_HeaderLinks = _resolveComponent("HeaderLinks")!
  const _component_HeaderContacts = _resolveComponent("HeaderContacts")!
  const _component_BurgerMenu = _resolveComponent("BurgerMenu")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_SiteLogo, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'home' }))),
      class: "header-logo"
    }),
    _createVNode(_component_HeaderLinks),
    _createVNode(_component_HeaderContacts),
    _createVNode(_component_BurgerMenu, { class: "header-burger-menu" })
  ]))
}