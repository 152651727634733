<template>
  <p :class="['flash-message', message.type]">{{ message.message }}</p>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Message } from "@/interfaces/components/messages";
import { PropType } from "vue/dist/vue";

export default defineComponent({
  name: "VueMessages",
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/base"

.flash-message
  padding: 20px 40px
  text-align: center
  color: white

  &.error
    background-color: #E62828

  &.success
    background-color: #20C863

  &.info
    background-color: #1f6fff
</style>
