import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-60dc5012"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-links" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLink = _resolveComponent("HeaderLink")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerLinks, (link, idx) => {
      return (_openBlock(), _createBlock(_component_HeaderLink, {
        link: link,
        key: idx
      }, null, 8, ["link"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerButtonLinks, (btnLink, btnIdx) => {
      return (_openBlock(), _createBlock(_component_ButtonLink, {
        link: btnLink,
        key: btnIdx
      }, null, 8, ["link"]))
    }), 128))
  ]))
}