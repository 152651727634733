import { AUCTION_API_BASE_URL } from "@/constants";
import {
  CarMarkResponse,
  CarModelResponse,
} from "@/interfaces/components/cars/AuctionCarTypes";

export async function getAuctionCarMarks(): Promise<CarMarkResponse> {
  return await fetch(`${AUCTION_API_BASE_URL}/cars/brands`, {
    method: "GET",
  }).then((response: Response) => {
    return response.json();
  });
}

export async function getAuctionCarModels(
  markId: number
): Promise<CarModelResponse> {
  return await fetch(`${AUCTION_API_BASE_URL}/cars/models/${markId}`, {
    method: "GET",
  }).then((response: Response) => {
    return response.json();
  });
}
