<template>
  <footer class="footer">
    <TopFooter></TopFooter>
    <MidFooter></MidFooter>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TopFooter from "@/blocks/TopFooter.vue";
import MidFooter from "@/blocks/MidFooter.vue";

export default defineComponent({
  name: "FooterVue",
  components: { MidFooter, TopFooter },
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/base"

footer
  background-color: $footer-bg-color
  color: $footer-color
  padding-bottom: 25px
</style>
