import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b26a26ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "contact-number",
    href: `tel:${_ctx.number}`
  }, _toDisplayString(_ctx.number), 9, _hoisted_1))
}