<template>
  <a class="contact-number" :href="`tel:${number}`">{{ number }}</a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContactPhone",
  props: {
    number: String,
  },
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/base"

.contact-number
  display: block
  font-weight: 700
  font-size: 22px
  color: $main-text-color
</style>
