import { createStore } from "vuex";
import AuctionCarsResponse, {
  AuctionCar,
  AuctionCarMarkFull,
  AuctionCarModelFull,
  AuctionCarsQueryParams,
} from "@/interfaces/components/cars/AuctionCarTypes";
import {
  getAuctionCars,
  resolveAuctionCarData,
  resolveAuctionCarsData,
  searchCarByVinNumber,
} from "@/api/auction/cars";
import { Message } from "@/interfaces/components/messages";
import { getAuctionCarMarks, getAuctionCarModels } from "@/api/auction/marks";
import {
  OwnCar,
  OwnCarsErrorResponse,
  OwnCarsQueryParams,
  OwnCarsResponse,
} from "@/interfaces/components/cars/OwnCarTypes";
import { getOwnCars, resolveOwnCarsData } from "@/api/own/cars";

export interface State {
  auctionCars: AuctionCar[];
  ownCars: OwnCar[];
  lastPage: number;
  messages: Message[];
  auctionCarMarks: AuctionCarMarkFull[];
  auctionCarModels: AuctionCarModelFull[];
  carsPreloader: boolean;
}

export default createStore({
  state: {
    auctionCars: [],
    ownCars: [],
    lastPage: 0,
    messages: [],
    auctionCarMarks: [],
    auctionCarModels: [],
    carsPreloader: false,
  } as State,
  getters: {
    getAuctionCar:
      (state) =>
      (carLot: string): AuctionCar[] => {
        return state.auctionCars.filter((car) => car.lots[0].lot == carLot);
      },
    getOwnCar:
      (state) =>
      (carId: number): OwnCar[] => {
        return state.ownCars.filter((car) => car.id == carId);
      },
    loadMoreAvailable:
      (state) =>
      (currentPage: number): boolean => {
        return currentPage < state.lastPage;
      },
  },
  mutations: {
    setLastPage(state, page: number) {
      state.lastPage = page;
    },
    addMessage(state, message: Message, deleteSec: number | null = 3) {
      state.messages.push(message);
      if (deleteSec) {
        setTimeout(() => {
          state.messages = [];
        }, deleteSec * 1000);
      }
    },
    resetMessages(state) {
      while (state.messages.length) {
        setTimeout(() => {
          state.messages.pop();
        }, 1000);
      }
    },
    extendAuctionCars(state, cars: AuctionCar[]) {
      state.auctionCars.push(...cars);
    },
    replaceAuctionCars(state, cars: AuctionCar[]) {
      state.auctionCars = cars;
    },
    extendOwnCars(state, cars: OwnCar[]) {
      state.ownCars.push(...cars);
    },
    replaceOwnCars(state, cars: OwnCar[]) {
      state.ownCars = cars;
    },
    setAuctionCarMarks(state, marks: AuctionCarMarkFull[]) {
      state.auctionCarMarks = marks;
    },
    setAuctionCarModels(state, models: AuctionCarModelFull[]) {
      state.auctionCarModels = models;
    },
    setCarsPreloader(state, preloader: boolean) {
      state.carsPreloader = preloader;
    },
    sortAuctionCars(
      state,
      ordering:
        | "price_asc"
        | "price_desc"
        | "auction_date_asc"
        | "auction_date_desc"
    ) {
      if (ordering === "price_asc") {
        state.auctionCars.sort((car1, car2) => {
          if (!car1.lots[0].bid) return -1;
          if (!car2.lots[0].bid) return 1;
          return car1.lots[0].bid - car2.lots[0].bid;
        });
      } else if (ordering === "price_desc") {
        state.auctionCars.sort((car1, car2) => {
          if (!car1.lots[0].bid) return 1;
          if (!car2.lots[0].bid) return -1;
          return car2.lots[0].bid - car1.lots[0].bid;
        });
      } else if (ordering === "auction_date_asc") {
        state.auctionCars.sort((car1, car2) => {
          if (!car1.lots[0].sale_date) return -1;
          if (!car2.lots[0].sale_date) return 1;
          return (
            car1.lots[0].sale_date?.getTime() -
            car2.lots[0].sale_date?.getTime()
          );
        });
      } else if (ordering === "auction_date_desc") {
        state.auctionCars.sort((car1, car2) => {
          if (!car1.lots[0].sale_date) return 1;
          if (!car2.lots[0].sale_date) return -1;
          return (
            car2.lots[0].sale_date?.getTime() -
            car1.lots[0].sale_date?.getTime()
          );
        });
      }
    },
  },
  actions: {
    async loadAuctionCars(context, params: AuctionCarsQueryParams) {
      context.commit("setCarsPreloader", true);
      if (
        context.state.lastPage === 0 ||
        (Number(params.page) || 1) <= context.state.lastPage
      ) {
        await getAuctionCars(
          Object.assign({}, params, {
            per_page: "30",
            status: "3",
          })
        ).then((data: AuctionCarsResponse) => {
          if (data.status !== 200) {
            const message: Message = {
              message: "Не удалось загрузить машин",
              type: "error",
            };
            context.commit("addMessage", message);
          } else {
            context.commit("setLastPage", data.meta.last_page);
            resolveAuctionCarsData(data.data);
            if (data.meta.current_page == 1) {
              context.commit("replaceAuctionCars", data.data);
            } else {
              context.commit("extendAuctionCars", data.data);
            }
          }
          context.commit("setCarsPreloader", false);
        });
      }
    },
    async loadAuctionCarMarks(context) {
      await getAuctionCarMarks().then((json) => {
        if (json.status !== 200) {
          const message: Message = {
            message: "Марок машин нет",
            type: "info",
          };
          context.commit("addMessage", message);
        } else {
          context.commit("setAuctionCarMarks", json.data);
        }
      });
    },
    async loadAuctionCarModels(context, markId: number) {
      await getAuctionCarModels(markId).then((json) => {
        if (json.status !== 200) {
          const message: Message = {
            message: "Моделей машин нет",
            type: "info",
          };
          context.commit("addMessage", message);
        } else {
          context.commit("setAuctionCarModels", json.data);
        }
      });
    },
    async loadOwnCars(context, params: OwnCarsQueryParams) {
      context.commit("setCarsPreloader", true);
      if (
        context.state.lastPage === 0 ||
        (Number(params.page) || 1) <= context.state.lastPage
      ) {
        await getOwnCars(Object.assign({}, params)).then(
          (data: OwnCarsErrorResponse | OwnCarsResponse) => {
            if ("detail" in data) {
              const message: Message = {
                message: data.detail,
                type: "error",
              };
              context.commit("addMessage", message);
            } else {
              if (data.next) {
                context.commit("setLastPage", Number(params.page) + 1);
              }
              resolveOwnCarsData(data.results);
              if (Number(params.page) || 1 == 1) {
                context.commit("replaceOwnCars", data.results);
              } else {
                context.commit("extendOwnCars", data.results);
              }
            }
            context.commit("setCarsPreloader", false);
          }
        );
      }
    },
    async loadCarWithVin(context, vin: string) {
      context.commit("setCarsPreloader", true);
      await searchCarByVinNumber(vin).then((data) => {
        if (data.status !== 200) {
          const message: Message = {
            message: "Не удалось найти машину с таким VIN номером",
            type: "error",
          };
          context.commit("addMessage", message);
        } else {
          context.commit("setLastPage", 1);
          resolveAuctionCarData(data.data);
          context.commit("replaceAuctionCars", [data.data]);
        }
        context.commit("setCarsPreloader", false);
      });
    },
  },
});
