<template>
  <router-link :to="link.link" class="header-link" v-if="link?.routerLink">
    {{ link.text }}
  </router-link>
  <a :href="link?.link" class="header-link" v-else>{{ link.text }}</a>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Link } from "@/interfaces/components/links/RouterLinkPropTypes";

export default defineComponent({
  name: "HeaderLink",
  props: {
    link: Object as PropType<Link>,
  },
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/base"

.header-link
  color: $main-text-color
  font-weight: 500
  font-size: 18px

  &:hover
    color: $header-link-hover-color
</style>
