<template>
  <div class="top-footer">
    <div class="content container">
      <div class="logo" @click="$router.push({ name: 'home' })">
        <img src="@/assets/img/svg/logo.svg" alt="site logo" />
        <span>Прозрачный импорт авто из-за границы в Россию</span>
      </div>
      <div class="top-footer-links">
        <DefaultLink
          v-for="(link, index) in topFooterLinks"
          :key="index"
          :link="link"
        ></DefaultLink>
      </div>
      <div class="top-footer-btn-links">
        <FooterButtonLink
          v-for="(btn, idx) in topFooterButtonLinks"
          :key="idx"
          :link="btn"
        ></FooterButtonLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DefaultLink from "@/components/links/DefaultLink.vue";
import { Link } from "@/interfaces/components/links/RouterLinkPropTypes";
import FooterButtonLink from "@/components/links/FooterButtonLink.vue";

export default defineComponent({
  name: "TopFooter",
  components: { FooterButtonLink, DefaultLink },
  data() {
    return {
      topFooterLinks: [
        { text: "Главная", link: { name: "home" }, routerLink: true },
        { text: "Для дилеров", link: "#!", routerLink: false },
        { text: "О компании", link: "#!", routerLink: false },
        { text: "Контакты", link: "#!", routerLink: false },
      ] as Array<Link>,
      topFooterButtonLinks: [
        { text: "ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ", link: "#!", routerLink: false },
      ],
    };
  },
});
</script>

<style scoped lang="sass">
@import "@/assets/sass/base"

.top-footer
  display: flex
  flex-direction: column

  &::after
    content: ""
    width: 100%
    height: 2px
    background: $top-footer-line-color

  & .content
    padding: 25px 0
    display: flex
    align-items: center
    justify-content: space-between

    & .logo
      display: flex
      align-items: center
      justify-content: space-between
      width: 25%
      column-gap: 20px

      & img
        width: 100%

    & .top-footer-links
      display: flex
      align-items: center
      justify-content: space-between
      width: 40%

      & .default-link
        font-size: 22px

    & .top-footer-btn-links
      display: flex
      align-items: center
      justify-content: space-between
      width: 20%
</style>
